<template>
	<div>
		<a-modal 
		okText="确定"
		cancelText="取消"
		v-model="visible" 
		:title="dialog.title" 
		@ok="comfirmmodel">
			<template>
				<a-form ref="collectionForm"  :form="form">
					<a-form-item>
						<span slot="label">
						  {{dialog.columns.id.label}}&nbsp;
						  <a-tooltip :title="`${dialog.title}${dialog.columns.id.label}`">
							<a-icon type="question-circle-o" />
						  </a-tooltip>
						</span>
						<a-input
						:disabled="dialog.columns.id.disable"
						  v-decorator="[
							dialog.columns.id.name,
							{
							  initialValue: dialog.data.id,
							  rules: [{ required: true, message: `请填写${dialog.columns.id.label}`, whitespace: true }],
							},
						  ]"
						/>
					</a-form-item>
					<template v-if="dialog.columns.service_id.label">
						<a-form-item >
							<span slot="label">
							  {{dialog.columns.service_id.label}}&nbsp;
							  <a-tooltip :title="`${dialog.title}${dialog.columns.service_id.label}`">
								<a-icon type="question-circle-o" />
							  </a-tooltip>
							</span>
							<a-input
							:disabled="dialog.columns.service_id.disable"
							  v-decorator="[
								
								dialog.columns.service_id.name,
								{
								  initialValue: dialog.data.service_id || '0',
								  rules: [{ required: true, message: `请填写${dialog.columns.service_id.label}`, whitespace: true }],
								},
							  ]"
							/>
						</a-form-item>
					</template>
					
					<!-- 修改分类 -->
					<template v-if="dialog.columns.pid.label && dialog.select">
						<a-form-item :label="dialog.columns.pid.label" has-feedback>
							<a-select
							@change = "getselect"
							  v-decorator="[
								dialog.columns.pid.name,
								{ 
									initialValue: dialog.select[0].id,
									rules: [{ required: true, message: '请填写${dialog.columns.pid.label}'}] 
								},
							  ]"
							>
								<a-select-option v-for="(item,index) in dialog.select" :key="item.id">
									{{item.name}}
								</a-select-option>
							 
							</a-select>
						</a-form-item>
					</template>
					
					<!-- 选择服务，级联选择器 -->
					<template v-if="dialog.columns.goods.label && dialog.goods">
						<a-form-item :label="dialog.columns.goods.label">
							
							<a-cascader
							  :options="dialog.goods"
							  expand-trigger="hover"
							  @change="getcascader"
							  v-decorator="[
								dialog.columns.goods.name,
								{
								  initialValue:cascader ,
								  rules: [{ required: true, message: `请填写${dialog.columns.goods.label}`}],
								},
							  ]"
							/>
						</a-form-item>
						
					</template>
					  
					
					<a-form-item :label="dialog.columns.name.label">
						<a-input
						  v-decorator="[
							dialog.columns.name.name,
							{
							  initialValue: dialog.data.name,
							  rules: [{ required: true, message: `请填写${dialog.columns.name.label}`, whitespace: true }],
							},
						  ]"
						/>
					</a-form-item>
					
					
					<template v-if="dialog.data.img || dialog.data.icon">
						<a-form-item label="更换图片" >
							  <img
							  v-decorator="[
								dialog.columns.icon.name,
								{
								  initialValue: dialog.data.img || dialog.data.icon,
								  rules: [{ required: true, message: `请填写${dialog.columns.icon.label}`, whitespace: true }],
								},
							  ]"
							   style="width: 22%;height: 60%;" :src="dialog.data.img || dialog.data.icon" alt="" @click="openresources">
						</a-form-item>
					</template>
					
				</a-form>
				<!-- 图片管理器 -->
				<template v-if="isresources">
					<resources @getimgpath="getimgpath"></resources>
				</template>
			</template>
			
		  
		</a-modal>
	</div>
</template>

<script>
	import {pathToBase64} from "../util/imgformat.js"
	import resources from "./resoureces.vue"
	export default {
		components:{
			resources
		},
		props:{
			dialog:Object
		},
		data() {
			return {
				isresources:false,
				visible: false,
				form: this.$form.createForm(this, { name: 'coordinated' }),
				base64:""
			}
		},
		computed:{
			// 级联选中默认
			cascader(){
				let arr = []
				arr.push(this.dialog.goods[0].value)
				arr.push(this.dialog.goods[0].children[0].value)
				console.log(this.dialog.goods[0].children[0].children[0].label,"三级");
				arr.push(this.dialog.goods[0].children[0].children[0].value)
				// [dialog.goods[0].id,dialog.goods[0].children[0].id,dialog.goods[0].children[0].children[0].id]
				return arr
			}
		},
		created() {

		},
		methods:{
			comfirmmodel(e){
				console.log(e);
				const form = this.$refs.collectionForm.form;
			    form.validateFields((err, values) => {
				    if (err) {
				       return;
				    }
				console.log('Received values of form: ', values);
				// 执行提交方法
				this.$emit("modifyrecent",values)
			  });
			},
			// 选择分类
			getselect(e){
				console.log(e,"获取选中分类id");
			},
			// 获取商品
			getcascader(e){
				console.log(e,"获取商品");
			},
			// 打开图片管理器
			openresources(){
				this.isresources = true
			},
			// 获取更改图片路径
			// 获取更改图片路径
			getimgpath(val){
				// 收集数据集
				let payload =  {
					status : "modify", //区分是增加还是修改传值
					val:val
				}
				this.$emit("changeimg",payload)
			},
		}
	}
</script>

<style scoped lang="less">

</style>

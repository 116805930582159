<template>
	<div>

		
			<a-card class="acard f-ac">
				<div class="f-ac">
					<div class="f-ac">
						<div class="tips">关键词：</div>
						<a-input-search class="ainputsearch" placeholder="请输入搜索内容" enter-button
							@search="onSearch" />
					</div>
				</div>
			</a-card>
		
		<!-- 
	  bordered :边框线
	  columns：表头
	  slots: { title: '是否显示' }, //插槽列
	  scopedSlots: { customRender: '是否显示' },//插槽列数据
	  @change="handleTableChange" //分页、排序、筛选变化时触发
	  -->
		<a-table 
		bordered 
		:columns="columns" 
		:data-source="datasource" 
		:pagination="pagination" 
		row-key="id"
		@change="handleTableChange"
		>
			<!-- 自定义表格列显示内容 -->
			<!-- 头像 -->
			<template  slot="avatar" slot-scope="avatar">
				<!-- {{show}} -->
				<img class="img-pic" :src="avatar" alt="" />
			</template>
			<template  slot="show_img" slot-scope="show_img">
				<!-- {{show}} -->
				<img class="img-pic" :src="show_img" alt="" />
			</template>

			<template slot="show" slot-scope="show">
				<!-- {{show}} -->
				<!-- <template v-if="show">
					<a-button type="primary" @click="openmodel">
						显示
					</a-button>
				</template>
				<template v-else>
					<a-button type="danger" @click="openmodel">
						禁止
					</a-button>
				</template> -->
			</template>
			
			<!-- 应急上门 -->
			<template  slot="icon" slot-scope="icon">
				<img class="img-pic" :src="icon" alt="" />
			</template>
			
			<!-- 最近活动自定义内容 -->
			
			<template  slot="img" slot-scope="img">
				<img class="img-pic" :src="img" alt="" />
			</template>
			
			<template slot="status" slot-scope="text,record">
				<template v-if="record.status == 1">
					     <a-popconfirm
							  okText="确定"
							  cancelText="取消"
					          v-if="datasource.length"
					          title="确认禁止?"
					          @confirm="() => warnmodel(record.id)"
					        >
					          <a-button type="primary">
					          	显示
					          </a-button>
					        </a-popconfirm>
					
				</template>
				<template v-else>
					<a-popconfirm
						okText="确定"
						cancelText="取消"
					     v-if="datasource.length"
					     title="确认显示?"
					     @confirm="() => warnmodel(record.id)"
					   >
					     <a-button type="danger">
					     	禁止
					     </a-button>
					   </a-popconfirm>
				</template>
			</template>
			
			
			<template slot="control" slot-scope="text,record">
				<!-- {{show}} -->
				<a-button type="primary" @click="opencontrol(record)">
					{{"修改"}}
				</a-button>
			</template>
			<template slot="set" slot-scope="set">
				<div class="set f-ajc">
					<template v-for="(item,index) in set">
						<div class="setitem f-ac">
							<div class="sort">
								<span class="txt">{{"排序"}}</span>
								<a-input class="a-input" placeholder="序号" />
							</div>
							<div class="switch">
								<span class="txt">{{item.name}}</span>
								<a-switch class="a-switch" default-checked @change="onChange" />
							</div>
						</div>
					</template>
				</div>
			</template>
			<template slot="blacklist" slot-scope="blacklist">
				<a-switch default-checked @change="onChange" />
			</template>
		</a-table>
	</div>
</template>
<script>
	export default {
		props: {
			columns: Array,
			datasource: Array,
			pagination: Object,
			
		},
		data() {
			return {

				sclectoption: [
					"jack",
					"Lucy",
					"Disabled",
					"yiminghe"
				]
			};
		},
		mounted() {

		},
		methods: {
			// 分页点击触发
			handleTableChange(pagination, filters, sorter) {
			  console.log(pagination);
			 this.$emit("gettabledata",pagination)
			},
			// 开关器
			onChange(checked) {
				console.log(`a-switch to ${checked}`);
			},
			// 搜索关键次
			onSearch(value) {
				console.log(value);
				this.$emit("getsearch",value)
			},
			handleselect(value) {
				console.log(`selected ${value}`);
			},
			// 警告提示框
			warnmodel(key) {
				// console.log("对话框",key);
				this.$emit("warnmodel", key)
			},
			// 打开操作框
			opencontrol(record) {
				this.$emit("opencontrol", record)
			}
		},
	};
</script>

<style lang="less" >
	.acard {
		background: @bg-color;

		.ainputsearch {
			width: 200px;
		}
	}
	.img-pic{
		width: 50px;
		height: 50px;
	}
	.set {
		flex-direction: column;

		.setitem {
			width: 100%;

			.sort {
				width: 40%;

				.a-input {
					width: 50px;
					padding-left: 10px;
					margin-left: 5px;
				}
			}

			.switch {
				flex: 1;

				.txt {}

				.a-switch {
					margin-left: 5px;
					padding-left: 10px;
				}
			}
		}
	}
</style>

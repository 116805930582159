<template>
	<div class="content">
		<!-- 表格 -->
		<Table 
		:columns="columns" 
		:datasource="datasource" 
		:pagination="pagination"  
		@opencontrol="opencontrol" 
		@gettabledata="gettabledata" 
		@getsearch="getsearch"
		@warnmodel="warnmodel">
		</Table>
		<!-- 操作框 -->
		<!-- <control-model ref="controlmodel" ></control-model> -->
	</div>
</template>

<script>
	import {mapState,mapGetters,mapMutations,mapActions} from "vuex";
	import Table from "../../components/table.vue"
	import controlModel from "../../components/controlmodel.vue"
	import pagination from "../../mixins/pagination.js"
	export default {
		mixins:[pagination],
		components:{
			Table,
			controlModel
		},
		data() {
			return {
				columns :[
				  {
				    title: 'ID',
					key:"id",
				    dataIndex: 'id',
				  },
				  {
				    title: '名称',
					key:"nickname",
				    dataIndex: 'nickname',
				  },
				  {
				    title: '手机号',
				  	key:"mobile",
				    dataIndex: 'mobile',
				  },
				  {
				    title: '余额',
					key:"balance",
				    dataIndex: 'balance',
				  },
				  {
				    title: '头像',
					key:"avatar",
				    dataIndex: 'avatar',
					slots: { title: '头像' }, //插槽列
					scopedSlots: { customRender: "avatar" },//插槽列数据
				  },
				  {
				    title: '注册时间',
				  	key:"register_time",
				    dataIndex: 'register_time',
				  },
				  {
				    title: '注册IP',
				  	key:"register_ip",
				    dataIndex: 'register_ip',
				  },
				  {
				    title: '最后登录时间',
				  	key:"last_login_time",
				    dataIndex: 'last_login_time',
				  },
				  {
				    title: '最后登录IP',
				  	key:"last_login_ip",
				    dataIndex: 'last_login_ip',
				  },
				  {
				    title: '黑名单',
				  	key:"status",
				    dataIndex: 'status',
					slots: { title: '黑名单' }, //插槽列
					scopedSlots: { customRender: "status" },//插槽列数据
				  },
				  {
				    title: '来源平台',
				  	key:"platform",
				    dataIndex: 'platform',
				  },
				],
				datasource:[]
			}
		},
		created() {
			console.log(this.pagination,"分页参数");
		},
		mounted() {
			this.gettabledata(this.pagination);
		},
		methods:{
			// 表格参数只需要接入不同的方法即可
			...mapActions({
				getdata:"user/getmember",
				getstatus:"user/getstatus"
			}),
			// 打开操作框
			opencontrol(val){
				this.$refs.controlmodel.visible = true
			},
			// 搜索框
			getsearch(val){
				this.pagination.keyword = val
				this.pagination.current = 0
				this.gettabledata(this.pagination);
			},
			//显示和禁止
			async warnmodel(val){
				// 请求状态数据
				let msg = await this.getstatus(val)
				this.$message.success(msg);
				this.pagination.current = 0
				this.gettabledata(this.pagination);
			}
			
		}
	}
</script>

<style>
</style>



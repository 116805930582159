
// 公用分页封装

export default {
	data() {
		return {
			pagination:{
				pageSize:4,
				current:1,
				totalPage:0,
				total:0,
				keyword:"",
				platform:""
			},
		}
	},
	methods:{
		/*
		 *pagination 分页参数
		 * 
		 */
		async gettabledata(pagination){
			 let data = await this.getdata(pagination)
			 this.pagination.current = pagination.current
			 this.pagination.total = data.total
			 this.pagination.totalPage = data.totalPage
			 this.datasource = data.data
		
		},
	}
}